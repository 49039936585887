import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'

import '../styles/global.css'
import { ContentMain } from '../components/common/content-main'
import { ContentSubPage } from '../components/common/content-sub-page'
import { HeaderTitle } from '../components/common/header-title'
import { CookiesBox } from '../components/cookies-box'
import { Footer } from '../components/footer'
import { Navigation } from '../components/navigation'
import { SectionStores } from '../components/section-stores'
import { SEO } from '../components/seo'
import { VideoTutorial } from '../components/video-tutorial'

const VideoTutorials = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'videoTutorials' })

  return (
    <>
      <SEO title={t('title')} />
      <div className="flex flex-col h-screen justify-between">
        <Navigation />
        <ContentMain>
          <HeaderTitle title={t('title')} />
          <ContentSubPage isFull>
            <VideoTutorial
              order="01"
              title={t('1.title')}
              videoUrl={t('1.videoUrl')}
            />
            <VideoTutorial
              order="02"
              title={t('2.title')}
              videoUrl={t('2.videoUrl')}
            />
            <VideoTutorial
              order="03"
              title={t('3.title')}
              videoUrl={t('3.videoUrl')}
            />
            <VideoTutorial
              order="04"
              title={t('4.title')}
              videoUrl={t('4.videoUrl')}
            />
            <VideoTutorial
              order="05"
              title={t('5.title')}
              videoUrl={t('5.videoUrl')}
            />
          </ContentSubPage>
          <SectionStores className="pt-10 pb-24" />
        </ContentMain>
        <Footer />
      </div>
      <CookiesBox />
    </>
  )
}

export default VideoTutorials

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

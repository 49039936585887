import PropTypes from 'prop-types'
import React from 'react'

import { VideoPlayer } from '../common/video-player'

const VideoTutorial = ({ order, title, videoUrl }) => (
  <div className="grid grid-cols-12 gap-y-2 lg:gap-y-0 py-8">
    <div className="col-span-12">
      <div className="text-2xl font-bold text-green text-center">{order}</div>
      <h3 className="text-xl font-bold pb-8 text-center uppercase">{title}</h3>
    </div>
    <div className="col-span-12 lg:col-span-10 lg:col-start-2 xl:col-span-8 xl:col-start-3">
      <VideoPlayer playerStyle="rounded-3xl shadow-video" url={videoUrl} />
    </div>
  </div>
)

VideoTutorial.propTypes = {
  order: PropTypes.string.isRequired,
  title: PropTypes.any.isRequired,
  videoUrl: PropTypes.string.isRequired,
}

export { VideoTutorial }
